.focus-outline,
.focus-outline-keynav {
  outline: none; /* disable the native focus outline (for :focus-visible) */
  transition: box-shadow var(--default-transition); /* animate our focus outline */

  &:focus {
    border-radius: var(--outline, 1px); /* add rounded corners to the outline */
    box-shadow: var(--outline, var(--default-outline));
    outline: var(--outline, var(--default-native-outline));
  }
}

.focus-outline-keynav {
  --outline: var(--is-not-keynav);
}
