@import 'ui/responsive-variables.css';

/* We want to tie the collapsible .d-panel-section to the <details> element, so: */
/* stylelint-disable selector-max-type */

:root {
  --d-panel-section-button-offset-y: calc((var(--small-component-size) - var(--heading-line-height)) / -2);
}

.d-panel-section {
  display: block;

  /*
   * If we need to allow content between panel sections and we don't want a separator in this case,
   * we should use + as the combinator instead of ~.
   */
  &:is(& ~ &) {
    border-top: 1px solid var(--gray-05);

    & .d-panel-section--heading,
    & > summary {
      padding-top: 4px;
    }
  }
}

:not(details).d-panel-section:not(:last-child),
details.d-panel-section[open]:not(:last-child) {
  margin-bottom: 16px;
}

.d-panel-section--heading,
details.d-panel-section > summary {
  color: var(--gray-08);
  display: block;
  font-size: var(--heading-font-size);
  font-weight: normal;
  line-height: var(--heading-line-height);
  margin: 4px; /* The outline is outside of the padding, so use some margin to make sure there's space */
  padding-bottom: 4px;
}

.d-panel-section--header,
details.d-panel-section > summary {
  cursor: pointer;
  list-style: none; /* hide default details marker */
  outline: none;
  transition: box-shadow var(--default-transition);
  user-select: none;

  &::-webkit-details-marker {
    display: none; /* hide default details marker in Firefox */
  }

  &::before {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iIzk5OWNhMCIgZD0iTTE2Ljg4LDguNzksMTIsMTMuNjcsNy4xMiw4Ljc5YTEsMSwwLDAsMC0xLjQxLDBoMGExLDEsMCwwLDAsMCwxLjQxbDUuNTksNS41OWExLDEsMCwwLDAsMS40MSwwTDE4LjMsMTAuMmExLDEsMCwwLDAsMC0xLjQxaDBBMSwxLDAsMCwwLDE2Ljg4LDguNzlaIi8+PC9zdmc+');
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    float: right;
    height: var(--small-component-size);
    margin-left: 4px;
    margin-top: var(--d-panel-section-button-offset-y);
    width: var(--small-component-size);
  }

  &:hover::before {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iIzM1M2I0NCIgZD0iTTE2Ljg4LDguNzksMTIsMTMuNjcsNy4xMiw4Ljc5YTEsMSwwLDAsMC0xLjQxLDBoMGExLDEsMCwwLDAsMCwxLjQxbDUuNTksNS41OWExLDEsMCwwLDAsMS40MSwwTDE4LjMsMTAuMmExLDEsMCwwLDAsMC0xLjQxaDBBMSwxLDAsMCwwLDE2Ljg4LDguNzlaIi8+PC9zdmc+');
  }

  &[disabled]::before {
    width: 0;
  }

}

details.d-panel-section[open] > summary {
  &::before {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iIzk5OWNhMCIgZD0iTTExLjI5LDguMjEsNS43LDEzLjhhMSwxLDAsMCwwLDAsMS40MWgwYTEsMSwwLDAsMCwxLjQxLDBMMTIsMTAuMzNsNC44OCw0Ljg4YTEsMSwwLDAsMCwxLjQxLDBoMGExLDEsMCwwLDAsMC0xLjQxTDEyLjcsOC4yMUExLDEsMCwwLDAsMTEuMjksOC4yMVoiLz48L3N2Zz4=');
  }

  &:hover::before {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iIzM1M2I0NCIgZD0iTTExLjI5LDguMjEsNS43LDEzLjhhMSwxLDAsMCwwLDAsMS40MWgwYTEsMSwwLDAsMCwxLjQxLDBMMTIsMTAuMzNsNC44OCw0Ljg4YTEsMSwwLDAsMCwxLjQxLDBoMGExLDEsMCwwLDAsMC0xLjQxTDEyLjcsOC4yMUExLDEsMCwwLDAsMTEuMjksOC4yMVoiLz48L3N2Zz4=');
  }
}

.is-keynav .d-panel-section--header:focus,
.is-keynav details.d-panel-section > summary:focus {
  border-radius: 1px;
  box-shadow: var(--default-outline);
}

/* https://github.com/javan/details-element-polyfill/issues/20 */
details.d-panel-section:not([open]) > *:not(summary) {
  display: none !important;
}

.d-panel-section--buttons {
  display: flex;
  float: right;
  margin-top: var(--d-panel-section-button-offset-y);

  & .button {
    margin-left: 4px;
  }
}

/**
 * Small Variant
 */

details.d-panel-section.d-panel-section-small {
  border-top: 0;

  & > summary {
    color: inherit;
    font-size: var(--default-font-size);
    height: 28px;
    margin: 0;
    padding-bottom: 0;
    padding-top: 0;
    user-select: none;

    &::before {
      content: none;
    }

    & > .details-clickable-label--container {
      align-items: center;
      display: flex;
      flex-grow: 1;
      overflow: hidden;
      padding-right: 4px;

      &::before {
        background-color: var(--gray-06);
        content: '';
        display: block;
        height: var(--icon-size-xs);
        mask-image: url('~@disy/cadenza-icons/chevron-right.svg');
        mask-repeat: no-repeat;
        width: calc(var(--icon-size-xs) + 4px);
      }

      &:hover {
        background-color: var(--gray-01);
      }

      &:active {
        background-color: var(--gray-02);
        color: var(--primary-color);

        &::before {
          background-color: var(--primary-color);
        }
      }
    }
  }

  &[open]:not(:last-child) {
    margin-bottom: 4px;
  }

  &[open] .details-clickable-label--container::before {
    mask-image: url('~@disy/cadenza-icons/chevron-down.svg');
  }

  &[open] > *:not(:first-child) {
    margin-left: 20px;
  }
}

.d-panel-section--toggle {
  margin-top: 16px;
}
