@import 'ui/responsive-variables.css';

.d-modal {
  --modal-top: var(--space-6);
  --modal-max-height: calc(100vh - var(--modal-top) * 2);
  --modal-padding-x: var(--default-page-margin);

  border: none;
  padding: 0;
  position: fixed;
  top: var(--modal-top);

  @media (--dialog-height-md-up) {
    --modal-top: var(--space-16);
  }

  & h2 {
    color: var(--gray-08);
  }
}

.d-modal.is-fullscreen .maximize,
.d-modal:not(.is-fullscreen) .minimize {
  display: none;
}

.d-modal--body {
  display: block;
  overflow-y: auto;
  overscroll-behavior: contain;
  padding: var(--space-4) var(--modal-padding-x);
  position: relative;

  &:empty {
    display: none;
  }
}

.d-modal--form {
  display: flex;
  flex-direction: column;
  position: relative;
}

.d-modal--header {
  margin: 0 var(--modal-padding-x);

  &:empty {
    display: none;
  }
}

.d-modal--scrollable {
  flex-basis: auto;
  flex-shrink: 1;
  max-height: 50vh; /* fixes scrollable + flex in IE11, is overwritten for other browsers below */
  min-height: 200px;
  overflow: auto;
}

@supports (height: fit-content) {
  .d-modal--scrollable {
    max-height: none; /* overwrites IE11 fix */
  }
}

.d-modal--alert {
  margin: var(--space-4) var(--modal-padding-x);

  .d-modal--body:not(:empty) ~ & {
    margin-top: 0;
  }
}

.d-modal--footer {
  align-items: center;
  border-top: 1px solid var(--gray-05);
  display: flex;
  flex-shrink: 0;
  gap: var(--space-2);
  margin: 0 var(--modal-padding-x);
  padding: var(--space-4) 0;

  &:empty {
    display: none;
  }

  & .button-link {
    margin-left: auto;
  }

  & .d-modal--loading-spinner {
    height: var(--button-size-m)
  }
}

.d-modal--overlay {
  background-color: #fff;
  height: 100%;
  left: 0;
  opacity: 0.65;
  position: absolute;
  top: 0;
  width: 100%;
}

.d-modal.is-fullscreen {
  --modal-padding-x: calc(var(--default-page-margin) + var(--space-2));

  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  overflow-y: auto;
  top: 0;
  transform: none;
  width: 100%;

  .d-modal--form {
    height: 100%;
    max-height: none;
  }

  .d-modal--header {
    padding-top: 19px; /* default padding-top + --space-2 */
  }

  .d-modal--body {
    flex: 1;
  }

  .d-modal--footer {
    padding-bottom: var(--space-6);
  }
}

@media (--dialog-xs-only) {
  .d-modal--footer .button {
    flex: 1 0 auto;
  }
}

/* not (--dialog-xs-only) */
@media (--dialog-height-sm-up) and (--sm-up) {
  .d-modal {
    width: 100%;

    &:not(.is-stacked) {
      transition:
        opacity var(--default-transition),
        transform var(--default-transition);

      &::backdrop {
        /* :root variables do not work in ::backdrop, see https://stackoverflow.com/a/63322762 */
        transition: opacity var(--default-transition, 100ms ease-in-out);
      }
    }

    &.closed {
      opacity: 0;
      transform: translateY(30px);

      &::backdrop {
        opacity: 0;
      }
    }
  }
}

.d-modal,
.d-modal--form {
  max-height: calc(100vh - var(--modal-top) * 2);
}

.d-modal:not(.is-fullscreen) {
  --modal-max-width: 600px;

  &.d-modal-s {
    --modal-max-width: 400px;
  }

  &.d-modal-l {
    --modal-max-width: 800px;
  }

  &.d-modal-xl {
    --modal-max-width: 1000px;
  }

  &.d-modal {
    /* this takes as max-width the minimum value between calc and --modal-max-width,
     leaving the original max width intended for each size or (if not possible because of lack of space)
      a width that leaves a 64px margin around the dialog */
    max-width: calc(100% - 2 * 64px);
    width: clamp(0px, 100%, var(--modal-max-width));
  }

  &.d-modal-max {
    max-width: none;
  }
}
