/* stylelint-disable selector-max-type */
@import '@fontsource/titillium-web/latin-300.css';
@import '@fontsource/titillium-web/latin-300-italic.css';
@import '@fontsource/titillium-web/latin-400.css';
@import '@fontsource/titillium-web/latin-400-italic.css';
@import '@fontsource/titillium-web/latin-600.css';
@import '@fontsource/titillium-web/latin-600-italic.css';

.text-muted {
  color: var(--muted-color);
}

.dark-bg .text-muted {
  color: var(--white-06);
}

small {
  --line-height: var(--small-line-height);

  font-size: var(--small-font-size);
  line-height: var(--line-height);
}

:root {
  --line-height: var(--default-line-height);

  color: var(--default-color);
  font-family: var(--default-font-family);
  font-size: var(--default-font-size);
  font-weight: var(--default-font-weight);
  line-height: var(--line-height);
}

p {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: calc(var(--line-height) / 2);
  }
}

h1,
.h1 {
  font-weight: var(--bold-font-weight);
}

/* reset legacy styling */
h1 span {
  font-size: inherit;
}

h2,
.h2 {
  font-weight: var(--default-font-weight);
}

h1,
.h1,
h2,
.h2 {
  font-family: var(--heading-font-family);
  font-size: var(--heading-font-size);
  line-height: var(--heading-line-height);

  &:first-child {
    margin-top: 0;
  }
}

/* Note: h3 is not an official part of our design system. */
h3 {
  font-family: var(--heading-font-family);
  font-size: var(--font-size-3);
  font-weight: var(--default-font-weight);
  line-height: 1.25em; /* 20px */

  &:first-child {
    margin-top: 0;
  }
}

@media (--touch) {
  h3 {
    font-size: var(--font-size-4);
    line-height: 1.11em; /* 20px */
  }
}

b,
strong,
.bold {
  font-weight: var(--bold-font-weight);
}

dl {
  line-height: 1.28em;
}

dt {
  font-weight: var(--bold-font-weight);
}

dd {
  margin-bottom: 8px;
  margin-left: 0;
}

.note {
  color: var(--gray-06);
  font-size: var(--small-font-size);
  .dark-bg & {
    color: var(--gray-01-opaque);
  }
}

p.note {
  margin-bottom: 8px;
}
