@import 'ui/common-variables.css';

.d-popup,
.d-popup--arrow {
  --d-popup-bg: white;
  --d-popup-z-index: 100;
}

.d-popup {
  --default-popup-width: 300px;

  background-color: var(--d-popup-bg);
  box-shadow: var(--default-box-shadow);
  min-width: 160px;
  outline: none;
  overflow-y: auto;
  position: fixed;
  z-index: var(--d-popup-z-index);
}

.d-info-popup {
  width: 256px; /* According to Anne, info popups should have this defined width */
}

.d-popup--arrow {
  --arrow-inner-size: 16px;
  --arrow-outer-size-main: 20px;
  --arrow-outer-size-orthogonal: 40px;

  background-color: transparent;
  display: block;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  z-index: var(--d-popup-z-index);

  &::before {
    background-color: var(--d-popup-bg);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
    content: '';
    display: block;
    height: var(--arrow-inner-size);
    overflow: hidden;
    position: relative;
    transform: rotate(45deg);
    width: var(--arrow-inner-size);
  }

  &[data-placement=top],
  &[data-placement=bottom] {
    height: var(--arrow-outer-size-main);
    width: var(--arrow-outer-size-orthogonal);

    &::before {
      left: calc((var(--arrow-outer-size-orthogonal) - var(--arrow-inner-size)) / 2);
    }
  }

  &[data-placement=top]::before {
    top: calc(-1 * var(--arrow-inner-size) / 2);
  }

  &[data-placement=bottom]::before {
    top: calc(var(--arrow-outer-size-main) - (var(--arrow-inner-size) / 2));
  }

  &[data-placement=left],
  &[data-placement=right] {
    height: var(--arrow-outer-size-orthogonal);
    width: var(--arrow-outer-size-main);

    &::before {
      top: calc((var(--arrow-outer-size-orthogonal) - var(--arrow-inner-size)) / 2);
    }
  }

  &[data-placement=left]::before {
    left: calc(-1 * var(--arrow-inner-size) / 2);
  }

  &[data-placement=right]::before {
    left: calc(var(--arrow-outer-size-main) - (var(--arrow-inner-size) / 2));
  }
}
