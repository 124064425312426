:root {
  --scroll-shadow-top: radial-gradient(farthest-side at 50% 0%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  --scroll-shadow-bottom: radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  --scroll-shadow-left: radial-gradient(farthest-side at 0% 50%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  --scroll-shadow-right: radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);

  /* Offset */
  --scroll-shadow-header-v-offset: 4px;
  --scroll-shadow-footer-v-offset: -4px;
}
