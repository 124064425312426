@import 'ui/responsive-variables.css';

/*
 * Note: The .form-control class is for use with native form controls only.
 */

/* reset legacy CSS */
.form-control {
  background: none;
  border-radius: 0;
  min-width: 0;
}

/* stylelint-disable-next-line no-duplicate-selectors */
.form-control { /* override global input styling */
  background-color: var(--gray-01);
  border: 1px solid transparent;
  box-shadow: none;
  box-sizing: border-box;
  color: var(--gray-08);
  display: block;
  font-family: var(--default-font-family);
  font-size: var(--default-font-size);
  outline: none;
  padding: 0 8px;
  transition:
    border-color var(--default-transition),
    box-shadow var(--default-transition);
  width: 100%;

  &::-ms-clear {
    display: none;
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &:focus {
    border-radius: 1px;
    box-shadow: var(--default-outline);
    outline: var(--default-native-outline);
  }

  &::placeholder {
    color: var(--gray-06);
    font-style: italic;
    opacity: 1;
    text-align: left;
  }

  &.with-disabled-overlay[disabled] {
    pointer-events: none;
    position: relative;
    user-select: none;

    &::after {
      background: var(--gray-01);
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.is-invalid:not(.is-disabled) .form-control {
  background-color: var(--alert-color-error-background);
  border-color: var(--alert-color-error);
}

/*
 * Prevent (or rather postpone) the autofill colors in Chrome.
 * See https://stackoverflow.com/a/68240841
 */
.form-control:-webkit-autofill {
  transition: background-color 600000s 0s, color 600000s 0s;
}

/* Details for certain types of form controls */

.form-control:not(textarea) {
  height: var(--default-component-size);
  line-height: var(--default-component-size);
}

textarea.form-control {
  padding: calc((var(--default-component-size) - var(--default-font-size)) / 2) 10px;
  resize: none;
}

input[type=range].form-control {
  padding: 0;
}

input[type=number].form-control {
  padding-right: 0;
  text-align: right;

  /* make number input button behave similar to chrome based browser behavior, display them on hover */
  @supports (-moz-appearance:button) {
    -moz-appearance: textfield;
    &:focus, &:hover {
      -moz-appearance: unset;
    }
  }

  &::-webkit-inner-spin-button {
    appearance: auto;
  }
}

select.form-control {
  appearance: none;

  /*
   * The background-image cannot be replaced with a mask-image, because
   * the <select> element does not support an ::after element.
   */
  background-image: url('~@disy/cadenza-icons/dropdown-arrow-12x24.svg?fill=353b44'); /* --default-color */
  background-position: right 10px center;
  background-repeat: no-repeat;
  border: 1px solid transparent;
  padding-right: var(--default-component-size);

  &:disabled {
    background-image: url('~@disy/cadenza-icons/dropdown-arrow-12x24.svg?fill=bdbfc2'); /* --gray-04-opaque */
  }

  &::-ms-expand {
    display: none;
  }
}

input[type=file].form-control {
  box-sizing: content-box;
  padding-bottom: 5px;
  padding-top: 5px;
  width: calc(100% - 22px); /* compensating box-sizing: content-box (100% - padding-left - padding-right - border * 2) */
}

/* Lightgray background */

.light-bg .form-control {
  background-color: var(--white-09);
}


/* Disabled background - need to be below .light-bg to override it */
.form-control:disabled,
.is-disabled .form-control {
  background-color: var(--gray-01);
  border-color: var(--gray-01);
  color: var(--gray-04);

  &::placeholder {
    color: var(--gray-04);
  }
}

/* Dark background */

.dark-bg .form-control {
  background-color: var(--white-01);
  color: var(--white-08);

  &:focus {
    box-shadow: 0 0 0 var(--default-outline-width) var(--white-04);
  }

  &::placeholder {
    color: var(--white-06);
  }
}
