/**
 * Similar to .is-hover-visible, but elements are hidden in a way that they do not consume space in the layout.
 *
 * Note: This toggles between `display: initial` and `display: none`. Other values are currently not supported.
 *
 * Caution: This (intentionally) causes layout jank.
 * Use this utility only if required by the design, otherwise use .is-hover-visible.
 */
@media (--hover) {
  .is-hover-displayed {
    :not(
      :is(
        .d-hover-context:is(:hover, .is-hover-active) &,
        :host(.d-hover-context:is(:hover, .is-hover-active)) &
      )
    )& {
      display: var(--is-keynav, none);
    }
  }
}
