.d-toast-message-parent {
  bottom: 15px;
  position: absolute;
  right: 16px;
  width: 300px;
  z-index: 1;

  .d-modal > & {
    position: fixed;
  }
}

.d-toast-message {
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  padding: 8px 16px;
  position: relative;
  transition: opacity 500ms ease-in-out;

  &.faded-out {
    opacity: 0.1; /* Transition until 0.1 instead of 0 to allow removal of the toast closer to when
                     it visually disappear */
    transition-duration: 1500ms;
  }

  & .d-toast-message--text {
    margin: 4px 0 4px 8px;
  }

  & .close-button {
    color: var(--white-09);
    position: absolute;
    right: 2px;
    top: 2px;
  }
}

.d-toast-message-info {
  background-color: var(--color-info);
  color: var(--white-08);
}

.d-toast-message-success {
  background-color: var(--color-success-dark);
  color: var(--white-08);
}

.d-toast-message-warning {
  background-color: var(--color-warning);
  color: var(--gray-08);
}

.d-toast-message-error {
  background-color: var(--color-error-dark);
  color: var(--white-08);
}
