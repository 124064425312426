.d-form-check {
  --padding: 4px;
  display: flex;
  line-height: var(--default-line-height);
  margin: 0 calc(-1 * var(--padding));
  padding: var(--padding);
  user-select: none;

  /*
   * The .form-check is a block element by default (it creates a line break),
   * but the click target should not be bigger than the label, so we set width: max-content.
   * Because of that, we also need to set the max-width, so that it doesn't overflow its parent.
   */
  width: max-content;
  max-width: 100%;

  /*
   * In multi-line .form-checks, the input should stay at the first line (flex-start).
   * The margin-top aligns it vertically with that first line.
   */
  & input[type=radio],
  & .d-switch {
    align-self: flex-start;
    flex-shrink: 0;
    margin: 0 4px 0 0;
  }

  & input[type=checkbox] {
    margin: 0 5px 0 1px;
  }

  & input {
    margin-top: 0;
  }

  & .d-switch {
    margin-top: 1px;
  }

  &.d-form-check-inline {
    align-items: center;
    display: inline-flex;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  .is-disabled &, &.is-disabled {
    color: var(--gray-03);
  }

  .is-invalid & {
    background-color: var(--color-error-10);
    color: var(--color-error);
  }

  /* Apparently we cannot nest multiple selectors at once. Bug in postcss-nesting? */
  .was-validated &:has(:invalid) {
    background-color: var(--color-error-10);
    color: var(--color-error);
  }
}

@media(--touch) {
  .d-form-check {
    padding: 8px 0;
  }
}
