/**
 * Buttons that are visible only on hover are not accessible on touch devices or via keyboard.
 * So we hide it only on non-touch devices and if the user is not navigating with the keyboard.
 *
 * A hover context shows all hover-visible buttons at once when hovered.
 */
@media (--hover){
  .is-hover-visible {
    transition: opacity var(--default-transition);

    :not(
      :is(
        .d-hover-context:is(:hover, .is-hover-active) &,
        :host(.d-hover-context:is(:hover, .is-hover-active)) &
      )
    )&:not(:is(:hover, [aria-expanded=true])) {
      opacity: var(--is-keynav, 0);
    }
  }
}
